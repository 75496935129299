
import { useEffect, useState } from 'react';

import { Radio, Space } from 'antd';

import { TimeSeries } from "pondjs";
import { format } from "d3-format";

import {
	Resizable,
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    LineChart,
	Baseline,
	styler
} from 'react-timeseries-charts';

function ChartIntervalSelect ({chartInterval, onIntervalChange}) {

	return (
		<div className="chartIntervals">
		<Radio.Group defaultValue={chartInterval} size="small" onChange={(e) => { onIntervalChange(e.target.value); }} >
			<Space>
			<Radio.Button value="3600">1Hr</Radio.Button>
			<Radio.Button value="14400">4Hr</Radio.Button>
			<Radio.Button value="86400">Day</Radio.Button>
			<Radio.Button value="172800">2Day</Radio.Button>
			<Radio.Button value="604800">Week</Radio.Button>
			<Radio.Button value="2678400">Mth</Radio.Button>
			<Radio.Button value="31536000">Year</Radio.Button>
			</Space>
		</Radio.Group>	
		</div>
	)

}

function ChartWithIntervalSelect ({chartInterval, chartType, chartParam, onIntervalChange}) {
		
	return (
		<div>
			<ChartIntervalSelect chartInterval={chartInterval} onIntervalChange={onIntervalChange}/>						
			<LiveChart chartInterval={chartInterval} chartParam={chartParam} chartType={chartType} />	
		</div>
	)
}


function LiveChart ({chartInterval, chartParam, chartType}) {

	const [chartData, setChartData] = useState(false);
	const [chartError, setChartError] = useState(false);
	const [chartTracker, setChartTracker] = useState({});

	useEffect(() => {

		const getNewChart = (hideLoading = false) => {
			var end = Math.round((new Date()).getTime() / 1000);
			var start = end - chartInterval;		
			let qstring = chartType + '/graph/?type=AVERAGE&start=' + start + '&end=' + end;
			
			if (!hideLoading) {	setChartData(false); }

			let fetchURL = '';
			if (process.env.NODE_ENV === "development") { fetchURL = ['http://10.0.69.2']; }

			fetch(fetchURL + "/parameters/" + qstring)
			.then(res => res.json())
			.then(
			(result) => {							
					var dats = [];
					for (let i in result) {
						var dd = result[i];
						var dato = new Date(i * 1000); // , 'yyyy-MM-dd HH:mm:ss');
						dats.push([dato,dd]);
					}
			
					const tsDats = new TimeSeries({
						name: "Graph",
						columns: ["time", "temp"],
						points: dats
					});
			
					setChartData(tsDats);
					//setIsLoaded(true);
					setChartError(false);				
			},
			(error) => {
				setChartError(error);
			})
		}
		getNewChart();
		let refreshInterval = 60000;
		if (chartInterval <= 14400) { refreshInterval = 5000; }		

		var chartTimer = setInterval(() => { getNewChart(true); }, refreshInterval);

		return () => { clearInterval(chartTimer); }
		
	}, [chartInterval, chartType])

	const handleTrackerChanged = (t, scale) => {
		setChartTracker({
			tracker: t,
			trackerEventData: t && chartData.at(chartData.bisect(t)),
			trackerX: t && scale(t)
		})
	}

	const style = styler([
		{ key: "temp", color: "#2a90cb", width: (window.innerWidth > 900 ? 2 : 1)}
	]);

	const markerStyle = {
		backgroundColor: 'rgba(0, 0, 0, 0.8)',
		color: "white",
		marginLeft: "5px"
		}

	const dateStyle = {
		fontSize: 12,
		backgroundColor: 'rgba(0, 0, 0, 0.8)',
		marginLeft: "5px",
		color: "#F4F4F4",
		borderWidth: 1,
		borderColor: "#AAA"
	};

	const tracker = chartTracker.tracker ? `${chartTracker.tracker.toLocaleString()}` : "";
	const formatter = format(".4s");

	if (chartError) {
		return ( <div>Error: {chartError.message}</div> );
	}
	
	if (!chartData) {		
		return ( <div className="chartLoadBox" height={window.innerWidth > 900 ? (window.innerHeight / 2) : 200}>Loading</div> );
	}

	return ( <div className="chartBox">
		{ chartTracker.tracker ?
					<div style={{position: 'relative'}}>
						<div style={{position: 'absolute', left: chartTracker.trackerX < (window.innerWidth / 2) ? chartTracker.trackerX : chartTracker.trackerX - 150}}>
							<div style={markerStyle}>{formatter(chartTracker.trackerEventData.get('temp')) + ' ' + chartParam.unit}</div>
							<div style={dateStyle}>{tracker}</div>
						</div>                                
					</div>
				: null }
		<Resizable>
			<ChartContainer
				showGridPosition="under"
				timeRange={chartData.timerange()}
				timeAxisTickCount={5}
				trackerPosition={chartTracker.tracker}
				trackerTimeFormat="%X"
				onTrackerChanged={handleTrackerChanged}
			>
				<ChartRow height={window.innerWidth > 900 ? (window.innerHeight - 250) : 200}>
					<YAxis
						id="temp"
						width="40"
						type="linear"
						format=",.0f"
						min={chartData.min("temp")}
						max={chartData.max("temp")}
						style={style.axisStyle("temp")}
					/>
					<Charts>
						<LineChart
							axis="temp"
							series={chartData}									
							columns={["temp"]}
							style={style}
							interpolation="curveBasis"
						/>
						<Baseline axis="temp" style={style} value={0}/>
					</Charts>
				</ChartRow>
			</ChartContainer>
		</Resizable>				
	</div>
	);
}

export { ChartWithIntervalSelect };