

import { Alert, Tag } from 'antd';
import { Link, useRouteMatch } from "react-router-dom";
 
function getTag(p) {

	let tagLookup = {'mate-CC-CC_mode': 	{'Bulk': ['Solar: Bulk', 'success'],
										 	'Float': ['Solar: Float', 'success'],
										 	'Absorb': ['Solar: Absorb', 'success'],
											'default': ['Solar: ' + p.value, 'default']
										 	},											 
					 'mate-FXR-INV_mode':	{'Inverting': ['Inverting', 'success'],
											'default': ['Inverter: ' + p.value, 'default']
											},
					 'mate-FXR-AC_mode':    {'default': ['Charger: ' + p.value, 'default']
											}
					}

	p.value = p.value.trim();
	
	let pmsg = '';
	let pcol = 'default';
	let pval = 'default';
	if (tagLookup[p.id]) {	
		if (tagLookup[p.id][p.value]) { pval = p.value; }		
		pmsg = tagLookup[p.id][pval][0];
		pcol = tagLookup[p.id][pval][1];
	} else {
		pmsg = p.id + ':' + p.value;		
	}

	return <Tag key={p.id} color={pcol}>{pmsg}</Tag>

}

function LiveDataTree ({paramList, chartType, onChartChange}) {
	
	var myGroups = {};
	var tags = [];
	var errorAlerts = [];
	for (let i in paramList) {
		let p = paramList[i];
		if (p.unit === 'tag') { tags.push(getTag(p)); continue; }
		if (p.unit === 'error' || p.unit === 'warning') {
			if (p.value === '["none"]' || p.value === '??') { continue; }			
			errorAlerts.push(<Alert message={(p.name + ': ' + p.value)} type={p.unit} />);
			continue;
		}
						
		let group = p.subtype;
		if (!myGroups[group]) { myGroups[group] = {}; }
		myGroups[group][paramList[i].id] = paramList[i];
	}
	const liveGroups = Object.keys(myGroups).map((groupName) => 
			<LiveGroup key={groupName} name={groupName} chartType={chartType} paramList={myGroups[groupName]} onChartChange={onChartChange} />
    );

	return (
		<div className="liveTree">
			{errorAlerts}
			{liveGroups} 
			<div className="tagBox">{tags}</div>    
		</div>
	);
}

function LiveGroup({paramList, name, chartType, onChartChange}) {
  const listItems = Object.keys(paramList).map((paramid) => 
    <LiveGroupItem key={paramid} id={paramid} data={paramList[paramid]} chartType={chartType} onChartChange={onChartChange} />
  );
  return (    
    <div>
      <h2 className="stattitle">{name}</h2>
      <table className="stattable">
		  <tbody>
          {listItems}
		  </tbody>
      </table>
    </div>
  );
}

function LiveGroupItem({data, chartType, onChartChange}) {

	let { url } = useRouteMatch();

	let agoClass = 'agoFresh';
	var nowtime = Date.now();
	if (nowtime - data.dtime > 60000) { agoClass = 'agoStale'; }

	if (data.graph === 'none') {

		return (
			<tr>
			<td>{data.name}</td>
			<td><span className={agoClass}>{data.value}</span> <span className="liveunit">{data.unit}</span></td>
			</tr>
		)

	} else {

		var selClass = '';
		if (chartType === data.id) { selClass = 'selectedGraph'; }
		return (
			<tr>				
			<td className={selClass}><Link to={`${url}?graph=${data.id}`} onClick={() => { onChartChange(data.id); }}>{data.name}</Link></td>
			<td><span className={agoClass}>{data.value}</span> <span className="liveunit">{data.unit}</span></td>
			</tr>
		)

	}

}

export { LiveDataTree }