import React, { useEffect, useState } from 'react';
import './App.css';

import { Layout, Menu } from 'antd';
import 'antd/dist/antd.dark.css'; // or 'antd/dist/antd.less'
import { ApiOutlined, DashboardOutlined, ThunderboltOutlined, BulbOutlined } from '@ant-design/icons';

import socketIOClient from "socket.io-client";

import { parse as queryParse } from "query-string";

import {
	BrowserRouter as Router,
	Redirect,
	Switch,
	Route,
	Link
} from "react-router-dom";

import { LiveDataTree } from "./components/LiveDataTree";
import { BMSDataTree } from "./components/BMSDataTree";
import { ChartWithIntervalSelect } from "./components/ChartWithIntervalSelect";
import { SonoffDataTree } from "./components/SonoffDataTree";

const { Header, Content } = Layout;
//const { Option } = Select;
 

function App() {

	const [currentMenu, setCurrentMenu] = useState('outback');
	const [socket, setSocket] = useState(null);

	// define the selected menu

	useEffect(() => {
		let socketURL = window.location.origin;
		if (process.env.NODE_ENV === "development") { socketURL = 'http://10.0.69.2'; }
		const newsocket = socketIOClient(socketURL);
		setSocket(newsocket);
		return () => newsocket.close();

	}, [setSocket]);

	useEffect(() => {

		// Show the correct menu on first load
		const urlMap = {'/solar': 'outback', '/air': 'awair', '/bms': 'bms', 'sonoff': '/sonoff'};
		const curURL = new URL(window.location);
		if (currentMenu !== urlMap[curURL.pathname]) {
			setCurrentMenu(urlMap[curURL.pathname]);			
		}
	}, [currentMenu]);

	return (
		<Router>
			<Layout>				
				<div className="logo" />
				<Header>
					<Menu theme="dark" mode="horizontal" onClick={(e) => setCurrentMenu(e.key)} selectedKeys={currentMenu}>
						<Menu.Item key="outback" icon={<ApiOutlined />}>
							<Link to="/solar?graph=mate-Sys_NetPower">Solar</Link>
						</Menu.Item>
						<Menu.Item key="awair" icon={<DashboardOutlined />}>
							<Link to="/air?graph=awair-score">Air</Link>
						</Menu.Item>
						<Menu.Item key="bms" icon={<ThunderboltOutlined />}>
							<Link to="/bms?graph=bms-pack_mv:1">BMS</Link>
						</Menu.Item>
						<Menu.Item key="sonoff" icon={<BulbOutlined />}>
							<Link to="/sonoff?graph=sonoff-power:483FDA2C09C9">Use</Link>
						</Menu.Item>
					</Menu>	
				</Header>							
					<Content>				
						<div className="site-layout-content">
							{ socket ? ( 
							<Switch>
								<Route exact path="/">
									<Redirect to="/solar?graph=mate-Sys_NetPower" />
								</Route>
								<Route path="/solar">
									<LiveDataWithChart socket={socket} menuType="outback"/>	
								</Route>
								<Route path="/air">
									<LiveDataWithChart socket={socket} menuType="awair"/>	
								</Route>
								<Route path="/bms">
									<LiveDataWithChart socket={socket} menuType="bms"/>	
								</Route>
								<Route path="/sonoff">
									<LiveDataWithChart socket={socket} menuType="sonoff"/>	
								</Route>
							</Switch>	
							) : ( <div>Connecting</div> ) }												
						</div>
					</Content>							
			</Layout>
		</Router>
	);
}

export default App;



function LiveDataWithChart ({socket, menuType}) {

	const [listLoaded, setListLoaded] = useState(false);
	const [paramList, setParamList] = useState(null);
	const [paramLookup, setParamLookup] = useState({});

	const [chartType, setChartType] = useState('mate-Sys_NetPower');
	const [chartInterval, setChartInterval] = useState(86400);

	// Initial load of parameter metadata
	useEffect(() => {
		if (!listLoaded) {

			let fetchURL = '';
			if (process.env.NODE_ENV === "development") { fetchURL = ['http://10.0.69.2']; }
			// set the selected chart from the URL if we have it
			fetch(fetchURL + "/parameters/")
			.then(res => res.json())
			.then((result) => {
				let newList = {};
				let nowtime = Date.now();
				let newParamLookup = {};
				for (let i in result) {
					newParamLookup[result[i].sid] = result[i].id;
					newList[result[i].id] = result[i];
					newList[result[i].id]['dtime'] = (nowtime - result[i].ago);
				}
				setParamList(() => {
					setListLoaded(true);
					setParamLookup(newParamLookup);
					return newList;
				});				
			});
		}
	}, [listLoaded]);

	// Set up the streaming of new realtime data, only after the list loaded
	useEffect(() => {
		
		socket.on("uq", data => {
			if (listLoaded) {				
				setParamList((prevParams) => {
					let newParams = {...prevParams};
					var nowtime = Date.now();
					for (let i in data) {
						let sid = data[i][0];
						let val = data[i][1];
						let paramId = paramLookup[sid];						
						if (paramId && newParams[paramId]) {
							newParams[paramId].value = val;
							newParams[paramId].dtime = nowtime;
						} else {
							console.log('received invalid param sid: ', sid, paramId); 						
						}

					}
					return newParams;
				})
			}
		})
	}, [socket, listLoaded, paramLookup])

	const { graph } = queryParse(window.location.search);
	useEffect(() => {
		setChartType(graph);
	}, [graph])
	
	const handleChartChange = (newChart) => {
		setChartType(newChart);
	}

	const handleChartIntervalChange = (newInterval) => {		
		setChartInterval(newInterval);
	}

	var myParamList = {};
	for (let i in paramList) {
		if (paramList[i].type === menuType) { 
			myParamList[i] = paramList[i]; 
		}
	}

	return (
		<div className="liveDataPane">
		  <div className="charttop">
			  { listLoaded ? (
			  	<ChartWithIntervalSelect chartInterval={chartInterval} chartType={chartType} chartParam={paramList[chartType]} onIntervalChange={handleChartIntervalChange}/>				
			  ) : ( <div></div> ) }
		  </div>
		  <div className="statleft">
			  {
			   menuType === 'bms' ? ( <BMSDataTree paramList={myParamList} chartType={chartType} onChartChange={handleChartChange}/> ) :
			   menuType === 'sonoff' ? ( <SonoffDataTree paramList={myParamList} chartType={chartType} onChartChange={handleChartChange}/> ) :
			   menuType === 'awair' ? ( <LiveDataTree key="awair" paramList={myParamList} chartType={chartType} onChartChange={handleChartChange}/> ) :
			   menuType === 'outback' ? ( <div>
											<LiveDataTree key="outback" paramList={myParamList} chartType={chartType} onChartChange={handleChartChange}/>
			 								<div className="tagBox"></div>
										  </div> ) : 
			    <div>Invalid menu</div>
			  }
		  </div>
	  </div>
  );

}

