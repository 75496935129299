
import { Link, useRouteMatch } from "react-router-dom";

function SonoffDataTree ({paramList, chartType, onChartChange}) {

	var sonoffs = {};
	for (let i in paramList) {
		if (paramList[i].type === 'sonoff') { sonoffs[i] = paramList[i]; }
	}
	return (
		<SonoffPackTable sonoffdata={sonoffs} chartType={chartType} onChartChange={onChartChange}/> 
	)
}

function BMSPackRow ({moduleid, chartType, onChartChange, packdata}) {

	let { url } = useRouteMatch();

	const pack = packdata;
	
	var nowtime = Date.now();

	var agoClass = 'agoFresh';
	if (nowtime - pack.dtime > 60000) { agoClass = 'agoStale'; }

	var lastoctet = pack['sonoff-ip'].split('.')[3];

	var cols = ['sonoff-state', 'sonoff-power', 'sonoff-voltage', 'sonoff-today', 'sonoff-signal'];
	var packcols = [];
	for (let i of cols) {
		let val = pack[i];
		let paramId = i + ':' + moduleid;
		if (i === 'sonoff-state') { 
			if (agoClass === 'agoStale') { val = '??'; } else { val = val ? '✅ ': '❌'; }
		}
		if (i === 'sonoff-today' && !isNaN(val)) { val = val * 1000; }
		let selClass = '';
		if (paramId === chartType) { selClass = 'selectedBMS'; }
		packcols.push( <td className={selClass}><Link to={`${url}?graph=${paramId}`} onClick={() => onChartChange(paramId)}>{val}</Link></td>)
	}

	const sonoffurl = `http://${pack['sonoff-ip']}`;

	return (
		<tr className={agoClass}>
			<td><a href={sonoffurl}>{lastoctet} {pack['sonoff-dn']}</a></td>
			{packcols}
		</tr>
	)

}


function SonoffPackTable ({sonoffdata, chartType, onChartChange}) {

	const packs = sonoffdata;

	var rowInfo = {};
	for (let i in packs) {
		let a = i.split(':');
		let packKey = a[0];
		let packId = a[1]; // .substring(a[1].length - 4);
		if (!rowInfo[packId]) { rowInfo[packId] = {}; }
		rowInfo[packId][packKey] = packs[i].value;
		rowInfo[packId]['dtime'] = packs[i].dtime;
	}

	const packRows = Object.keys(rowInfo).map((key) => <BMSPackRow key={key} moduleid={key} chartType={chartType} onChartChange={onChartChange} packdata={rowInfo[key]} /> );
	return (
		<table className="SonoffTable">
			<thead>
				{/* <tr>
					<th></th>
					<th colSpan="2">Temp <span className="liveunit">C</span></th>
					<th colSpan="2">Pack Total</th>
					<th colSpan="2">FET</th>
				</tr> */}
				<tr>
					<th>Name</th>
					<th></th>
					<th><span className="liveunit">W</span></th>
					<th><span className="liveunit">V</span></th>
					{/* 
					<th><span className="liveunit">A</span></th> */}
					<th><span className="liveunit">Wh</span></th>
					<th><span className="liveunit">dBm</span></th>
				</tr>
			</thead>
			<tbody>
				{packRows}
			</tbody>
		</table>
	)
}


export { SonoffDataTree };