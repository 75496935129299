
import { Tabs } from 'antd';
import { Link, useRouteMatch } from "react-router-dom";

const { TabPane } = Tabs;

function BMSDataTree ({paramList, chartType, onChartChange}) {

	var packs = {};
	var cells = {};
	for (let i in paramList) {
		if (paramList[i].subtype === 'Pack') { packs[i] = paramList[i]; }
		if (paramList[i].subtype === 'Cell') { cells[i] = paramList[i]; }
	}
	
	return (
		<Tabs defaultActiveKey="1">
			<TabPane tab="Battery Modules" key="1">
				<BMSPackTable packdata={packs} chartType={chartType} onChartChange={onChartChange}/> 
			</TabPane>
			<TabPane tab="Battery Cells" key="2">
				<BMSCellTable celldata={cells}/> 
			</TabPane>
		</Tabs>
	)
}

function BMSPackRow ({moduleid, chartType, onChartChange, packdata}) {

	let { url } = useRouteMatch();

	const pack = packdata;
	
	var nowtime = Date.now();

	var agoClass = 'agoFresh';
	if (nowtime - pack.dtime > 60000) { agoClass = 'agoStale'; }

	var cols = ['bms-battemp', 'bms-bmstemp', 'bms-pack_mv', 'bms-pack_ma', 'bms-chgfet', 'bms-dsgfet'];
	var packcols = [];
	for (let i of cols) {
		let val = pack[i];
		let paramId = i + ':' + moduleid;
		if (i === 'bms-chgfet' || i === 'bms-dsgfet') { 
			if (agoClass === 'agoStale') { val = '??'; } else { val = val ? '✅ ': '❌'; }
		}
		let selClass = '';
		if (paramId === chartType) { selClass = 'selectedBMS'; }
		packcols.push( <td className={selClass}><Link to={`${url}?graph=${paramId}`} onClick={() => onChartChange(paramId)}>{val}</Link></td>)
	}

	return (
		<tr className={agoClass}>
			<td>{moduleid}</td>
			{packcols}
		</tr>
	)

}


function BMSPackTable ({packdata, chartType, onChartChange}) {

	const packs = packdata;

	var rowInfo = {};
	for (let i in packs) {
		let a = i.split(':');
		let packKey = a[0];
		let packId = a[1];
		if (!rowInfo[packId]) { rowInfo[packId] = {}; }
		rowInfo[packId][packKey] = packs[i].value;
		rowInfo[packId]['dtime'] = packs[i].dtime;
	}

	const packRows = Object.keys(rowInfo).map((key) => <BMSPackRow key={key} moduleid={key} chartType={chartType} onChartChange={onChartChange} packdata={rowInfo[key]} /> );
	return (
		<table className="BMSPackTable">
			<thead>
				<tr>
					<th></th>
					<th colSpan="2">Temp <span className="liveunit">C</span></th>
					<th colSpan="2">Pack Total</th>
					<th colSpan="2">FET</th>
				</tr>
				<tr>
					<th>#</th>
					<th>Bat</th>
					<th>BMS</th>
					<th><span className="liveunit">mV</span></th>
					<th><span className="liveunit">mA</span></th>
					<th>C</th>
					<th>D</th>					
				</tr>
			</thead>
			<tbody>
				{packRows}
			</tbody>
		</table>
	)
}

function BMSCellTable (props) {
	
	const cell = props.celldata;

	// the data from the backend contains both mV and cell balance
	// we need to synthesize this into one value

	var cellInfo = {};
	for (let i in cell) {
		let n = i.split(':');
		if (!cellInfo[n[1]]) { cellInfo[n[1]] = {}; }
		if (n[0] === 'bms-cell_mv') {
			cellInfo[n[1]]['mv'] = cell[i].value;
			cellInfo[n[1]]['dtime'] = cell[i].dtime;			
		} else if (n[0] === 'bms-cell_bal') {
			cellInfo[n[1]]['bal'] = cell[i].value;
		}
	}


	const cellRows = Object.keys(cellInfo).map((key) => <BMSCellRow key={key} moduleid={key} celldata={cellInfo[key]} /> );

	return (
		<table>
			<tbody>
				{cellRows}
			</tbody>
		</table>
	)
}

function BMSCellRow (props) {
	
	var cell = props.celldata;	

	var mvs = JSON.parse(cell.mv);
	var bal = JSON.parse(cell.bal);
	
	var nowtime = Date.now();

	const cellMin = mvs.reduce((a,b) => (a < b) ? a : b, Infinity); 
	const cellMax = mvs.reduce((a,b) => (a > b) ? a : b, 0); 

	var cellCells = [[],[]];
	for (let i in mvs) { 
		let cellRow = Math.floor(i / 6);
	
		// check this against the highest and lowest cell
		let cellDev = 0;
		if (mvs[i] - cellMin > cellMax - mvs[i]) { 
			cellDev = mvs[i] - cellMin;
		} else {
			cellDev = cellMax - mvs[i];
		}		
		
		let cellClass = '';
		if (bal[i]) { 
			cellClass = 'cellBal'; 
		} else if (cellDev < 15) {
			cellClass = 'cellGood';
		} else if (cellDev < 20) {
			cellClass = 'cellWarn';
		} else {
			cellClass = 'cellDanger';
		}
		if (nowtime - cell.dtime > 60000) { cellClass = 'agoStale'; }

		cellCells[cellRow].push(
			<td key={i} className={cellClass}>{mvs[i]}</td>
		)
	}

	return ( 
		<tr>
			<td className="BMSModuleID">{props.moduleid}</td>
			<td>
				<table className="BMSCellTable"><tbody>
					<tr>{cellCells[0]}</tr>
					<tr>{cellCells[1]}</tr>
				</tbody></table>
			</td>
		</tr>

	)
}

export { BMSDataTree };